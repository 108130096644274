<!-- =========================================================================================
    File Name: ChatNavbar.vue
    Description: Chat Application - Chat navbar
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div v-if="userId != null" class="chat__header">
    <vs-navbar class="p-4 flex navbar-custom shadow" color="white" type="flat">
      <div class="relative flex mr-4">
        <i
          class="mr-2 mt-2 cursor-pointer notranslate material-icons"
          v-if="isSidebarCollapsed"
          @click.stop="$emit('open-contacts-sidebar')"
          >arrow_back</i
        >
        <vs-avatar
          v-if="contact.profilePic !== ''"
          class="m-0 border-2 border-solid border-white"
          size="40px"
          :src="contact.profilePic"
          text-color="#000"
          @click.stop="$emit('show-profile-sidebar', userId)"
        />
        <vs-avatar
          v-if="contact.profilePic === ''"
          class="m-0 border-2 border-solid border-white"
          size="40px"
          :text="contactNameInitials"
          :src="contact.profilePic"
          text-color="#000"
          @click.stop="$emit('show-profile-sidebar', userId)"
        />
      </div>
      <div class="truncate" :class="{ 'my-1 pt-2': contact.url }">
        <h4 class="text-lg">
          {{ contactName }}
        </h4>

        <p class="text-sm text-gray-600">ID: {{ contact.id }}</p>

        <a
          id="navbar-url"
          class="navbar-label truncate"
          :href="contact.url"
          target="_blank"
          v-if="contact.url"
        >
          <small>{{ contactUrl }}</small>
        </a>
      </div>
      <vs-spacer></vs-spacer>
      <postponed-conversation-tag
        v-if="contact.postponedTo"
        :date="contact.postponedTo"
        class="mr-2 p-2 px-3 bg-blac truncate"
        complete
        cancelable
        @onCancel="postponeConversation(null)"
      ></postponed-conversation-tag>

      <vs-dropdown
        v-else
        vs-custom-content
        class="self-center cursor-pointer"
        :disabled="contact.closed"
        vs-trigger-click
      >
        <vs-button
          type="border"
          size="small"
          icon="icon-moon"
          icon-pack="feather"
          class="mr-4 p-2 px-3 bg-blac truncate"
          :disabled="contact.closed"
          data-tooltip="Adiar Conversa"
        >
          Adiar
        </vs-button>
        <vs-dropdown-menu style="width: 270px">
          <vs-dropdown-item class="pt-3 pb-2 text-center">
            Adiar para
          </vs-dropdown-item>
          <vs-divider class="d-theme-border-grey-light my-0" />

          <div :key="i" v-for="(adiamento, i) in adiamentos">
            <vs-dropdown-item class="p-0">
              <div
                class="flex justify-between p-2 rounded-lg w-full text-black hover:bg-grey-light"
                color="white"
                type="filled"
                @click.stop="
                  adiamento.date
                    ? postponeConversation(adiamento.date)
                    : (activePostponeDatePicker = true)
                "
              >
                <span>{{ adiamento.label }}</span>
                <div v-if="adiamento.date">
                  <span
                    v-if="
                      adiamento.date.dayOfYear() === currentDate.dayOfYear()
                    "
                  >
                    {{ adiamento.date.locale("pt").format("HH:mm") }}
                  </span>
                  <span v-else>
                    {{ adiamento.date.locale("pt").format("ddd") }},
                    {{ adiamento.date.locale("pt").format("HH:mm") }}
                  </span>
                </div>
              </div>
            </vs-dropdown-item>
          </div>
        </vs-dropdown-menu>
      </vs-dropdown>

      <vs-popup :active.sync="activePostponeDatePicker" title="Custom Date">
        <div style="display: flex; justify-content: center">
          <date-picker
            type="datetime"
            inline
            confirm
            :show-second="false"
            @confirm="changeDatePicker"
            v-model="datePickerValue"
          ></date-picker>
        </div>
      </vs-popup>

      <vs-dropdown
        vs-custom-content
        class="self-center cursor-pointer"
        vs-trigger-click
        :disabled="contact.closed"
      >
        <vs-button
          type="border"
          size="small"
          icon="icon-send"
          icon-pack="feather"
          class="mr-4 p-2 px-3 bg-blac truncate"
          :disabled="contact.closed"
          data-tooltip="Atendente"
        >
          <a href="#" class="navbar-label px-1 inline-flex" role="listitem"
            ><span class="hidden sm:block mr-1">Atendente:</span>
            {{ getOperatorName(contact.operator) }}</a
          >
        </vs-button>
        <vs-dropdown-menu style="width: 300px">
          <vs-dropdown-item class="pt-3 pb-2 text-center">
            {{ $t("ForwardConversation") }}
          </vs-dropdown-item>
          <vs-divider class="d-theme-border-grey-light my-0" />
          <div class="flex flex-wrap justify-center p-3">
            <div class="w-full mb-2">
              <small>{{ $t("Intention") }}</small>
              <v-select
                id="department"
                v-model="department"
                :clearable="false"
                :options="intentionsOptions"
                name="segment"
                class="vselectNoWrap"
              />
            </div>
            <div class="w-full mb-2" v-if="entities.length > 0">
              <small>{{ $t("EntityKey") }}</small>
              <v-select
                v-model="entity"
                :clearable="true"
                :options="entitiesOptions"
                name="entities"
                class="vselectNoWrap"
              />
            </div>
            <div class="w-full mb-2">
              <small>{{ $t("User") }}</small>
              <v-select
                id="user"
                v-model="user"
                :clearable="false"
                :options="operatorsList"
                name="segment"
                class="vselectNoWrap"
                :no-data-text="$t('NoRecordsFounded')"
              >
                <template v-slot:option="option">
                  <div class="flex items-center">
                    <OnlineCircleComponent
                      :online="isOnline(option.lastSeenDate)"
                      class="mr-3"
                    />
                    {{ option.label }}
                  </div>
                </template>
              </v-select>
            </div>
            <div class="w-full">
              <small>Exibir Administradores?</small>
              <vs-switch color="#28c76f" v-model="addAdm" />
            </div>
          </div>
          <vs-dropdown-item class="p-0">
            <vs-button class="w-full" type="filled" @click.stop="setOperator()">
              Encaminhar
            </vs-button>
          </vs-dropdown-item>
        </vs-dropdown-menu>
      </vs-dropdown>

      <vs-button
        v-if="!contact.closed"
        class="w-ful mr-5 btn-close border-danger"
        color="danger"
        icon-pack="feather"
        icon="icon-x-circle"
        size="small"
        type="filled"
        @click.stop="activeCloseConversationPopup = true"
      >
      </vs-button>
    </vs-navbar>
    <vs-popup
      :active.sync="activeCloseConversationPopup"
      :title="$t('CloseConversation')"
    >
      <vs-row>
        <vs-col vs-w="6" vs-offset="3">
          <vs-select
            v-model="closingReason"
            :label="$t('SelectClosingReason')"
            width="100%"
          >
            <vs-select-item
              v-for="(item, index) in closingConversationReasons"
              :key="index"
              :value="item"
              :text="item.name"
            />
          </vs-select>
        </vs-col>
        <template v-if="closingReason && closingReason.id === 1">
          <vs-col
            vs-w="6"
            vs-offset="3"
            class="mt-4"
            v-for="(item, index) in closingReason.subReasons"
            :key="index"
          >
            <vs-input
              v-if="item.name === 'Valor de venda'"
              v-model.lazy="closingReasonSaleValue"
              v-money="money"
              :label="item.name"
              class="w-full"
            />
            <vs-input
              v-else
              v-model.lazy="saleCustomValue[item.name]"
              :label="item.name"
              class="w-full"
            />
          </vs-col>
        </template>
        <vs-col
          vs-w="6"
          vs-offset="3"
          class="mt-4"
          v-else-if="
            closingReason &&
            closingReason.id !== 1 &&
            closingReason.subReasons &&
            closingReason.subReasons.length > 0
          "
        >
          <vs-select
            v-model="closingSubReason"
            :label="closingReason.name"
            width="100%"
          >
            <vs-select-item
              v-for="(item, index) in closingReason.subReasons"
              :key="index"
              :value="item"
              :text="item.name"
            />
          </vs-select>
        </vs-col>
        <vs-col
          vs-w="6"
          vs-offset="3"
          vs-type="flex"
          vs-justify="center"
          class="mt-4"
        >
          <vs-button
            color="danger"
            icon-pack="feather"
            icon="icon-x"
            @click="acceptCloseChat"
            :disabled="!canClose"
          >
            {{ $t("CloseConversationAccept") }}
          </vs-button>
        </vs-col>
      </vs-row>
    </vs-popup>
  </div>
</template>

<script>
import vSelect from "vue-select";
import { VMoney } from "v-money";
import PostponedConversationTag from "@/components/PostponedConversationTag.vue";
import moment from "moment";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/locale/pt-br";
import "vue2-datepicker/index.css";
import OnlineCircleComponent from "@/components/OnlineCircleComponent.vue";
import { differenceInMinutes } from "date-fns";
export default {
  components: {
    "v-select": vSelect,
    PostponedConversationTag,
    DatePicker,
    OnlineCircleComponent,
  },
  props: {
    contact: {
      type: Object,
      required: true,
    },
    userId: {
      type: [String, Number],
      required: true,
    },
    isPinnedProp: {
      type: Boolean,
      required: true,
    },
    isSidebarCollapsed: {
      type: Boolean,
      required: true,
    },
    intentions: {
      type: Array,
    },
    entities: {
      type: Array,
    },
    operators: {
      type: Array,
    },
    closingConversationReasons: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      saleCustomValue: {},
      customSaleReason: "",
      inboxData: null,
      adiamentos: [
        {
          label: "Hoje, mais tarde",
          date: moment().hour(17).minute(0),
        },
        {
          label: "Amanhã",
          date: moment()
            .date(moment().date() + 1)
            .hour(9)
            .minute(0),
        },
        {
          label: "Próxima semana",
          date: moment().add(1, "weeks").startOf("isoWeek").hour(9).minute(0),
        },
        {
          label: "Próximo mês",
          date: moment()
            .add(1, "months")
            .date(1)
            .startOf("isoMonth")
            .hour(9)
            .minute(0),
        },
        {
          label: "Escolher data",
        },
      ],
      datePickerValue: null,
      activePostponeDatePicker: false,
      isPinnedLocal: this.isPinnedProp,
      user: "",
      department: "",
      entity: "",
      activeCloseConversationPopup: false,
      closingReason: "",
      closingLossReason: "",
      closingReasonSaleValue: "",
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
        masked: false,
      },
      closingSubReason: "",
      canClose: false,
      addAdm: false,
      operatorsNormalList: [],
      operatorsAdmsList: [],
    };
  },
  mounted() {
    this.operators.forEach((operator) => {
      operator.acl === "admin"
        ? this.operatorsAdmsList.push(operator)
        : this.operatorsNormalList.push(operator);
    });
    // order by operators name
    this.operatorsNormalList.sort((a, b) =>
      a.label.toLowerCase().localeCompare(b.label.toLowerCase())
    );
    this.operatorsAdmsList.sort((a, b) =>
      a.label.toLowerCase().localeCompare(b.label.toLowerCase())
    );
    // order by online / offline
    this.operatorsNormalList.sort(
      (a, b) => this.isOnline(b.lastSeenDate) - this.isOnline(a.lastSeenDate)
    );
    this.operatorsAdmsList.sort(
      (a, b) => this.isOnline(b.lastSeenDate) - this.isOnline(a.lastSeenDate)
    );
  },
  directives: { money: VMoney },
  watch: {
    addAdm() {
      this.user = "";
    },
    isPinnedProp(val) {
      this.isPinnedLocal = val;
    },
    department() {
      this.user = "";
    },
    entity() {
      this.user = "";
    },
    closingReason(val) {
      this.canClose = !(val && val.subReasons && val.subReasons.length > 0);
      this.closingSubReason = "";
    },
    closingSubReason(val) {
      if (val && val.name !== "Valor de venda") {
        this.canClose = true;
      }
    },
    closingReasonSaleValue(val) {
      this.canClose = val !== "R$ 0,00";
    },
    activeCloseConversationPopup() {
      this.saleCustomValue = {};
      this.closingReason = "";
      this.closingSubReason = "";
      this.closingReasonSaleValue = "";
      this.canClose = false;
    },
  },
  computed: {
    currentDate() {
      return moment().locale("pt_br");
    },
    intentionsOptions() {
      let intentions = JSON.parse(JSON.stringify(this.intentions));
      const index = intentions.findIndex((el) => el === "__all__");
      if (index > -1) intentions.splice(index, 1);
      return [...new Set(intentions)];
    },
    entitiesOptions() {
      return ["", ...this.entities];
    },
    userImg() {
      return this.contact.img;
    },
    contactName() {
      if (!this.contact.name) return this.$t("UnknownName");
      return this.contact.name.length > 16
        ? `${this.contact.name.slice("0", 16)}...`
        : this.contact.name;
    },
    contactUrl() {
      return this.contact.url.length > 30
        ? `${this.contact.url.slice(0, 30)}...`
        : this.contact.url;
    },
    contactNameInitials() {
      var name = this.contact.name || "?";
      var initials = name.match(/\b\w/g) || [];
      return ((initials.shift() || "") + (initials.pop() || "")).toUpperCase();
    },
    getStatusColor() {
      return (isActiveUser) => {
        const userStatus = this.getUserStatus(isActiveUser);
        if (userStatus === "online") {
          return "success";
        } else if (userStatus === "do not disturb") {
          return "danger";
        } else if (userStatus === "away") {
          return "warning";
        } else {
          return "grey";
        }
      };
    },
    operatorsList() {
      let operatorsVisible = [];
      let operators = [];
      operators = operators.concat(this.operatorsNormalList);
      if (this.addAdm) operators = operators.concat(this.operatorsAdmsList);
      for (var i in operators) {
        let found = false;
        if (operators[i].intentions)
          if (
            (operators[i].intentions.includes("__all__") ||
              operators[i].intentions.includes(this.department)) &&
            (!operators[i].entities ||
              operators[i].entities.length === 0 ||
              operators[i].entities.includes(this.entity))
          )
            found = true;
        if (operatorsVisible.indexOf(operators[i]) === -1 && found)
          operatorsVisible.push(operators[i]);
      }
      return operatorsVisible;
    },
  },
  methods: {
    isOnline(time) {
      if (!time) return null;
      let date_time_to = new Date(Date.parse(time));
      let valid = new Date(time).getTime() > 0;
      if (!valid) date_time_to = new Date(time);
      let date_time_from = new Date();
      let minutes = differenceInMinutes(date_time_from, date_time_to);
      return minutes <= 10;
    },
    changeDatePicker(date) {
      this.activePostponeDatePicker = false;
      this.datePickerValue = null;
      this.postponeConversation(date);
    },
    postponeConversation(date) {
      window.analytics.track(
        "Adiar Conversa",
        {},
        { groupId: this.$store.state.acc.current_acc.id }
      );
      this.$emit("postpone-conversation", { postponedTo: date });
    },
    getUserStatus(isActiveUser) {
      return isActiveUser ? this.$store.state.user.status : this.contact.status;
    },
    getOperatorName(operadorId) {
      const foundedOperator = this.operators.find(
        (operator) => operator.value === operadorId
      );
      if (foundedOperator) {
        const operatorName = foundedOperator.label.trim().split(" ");
        const formatedOperatorName =
          operatorName[0].length > 11
            ? `${operatorName[0].slice(0, 11)}`
            : operatorName[0];

        return foundedOperator.value !== "" ? formatedOperatorName : "";
      } else return "";
    },
    setOperator() {
      if (!this.department) {
        this.$vs.notify({
          time: 5000,
          title: this.$t("Error"),
          text: this.$t("FormValidationGeneric"),
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
          position: "top-right",
        });
      } else {
        window.analytics.track(
          "Transferir Conversa",
          {},
          { groupId: this.$store.state.acc.current_acc.id }
        );
        this.$emit(
          "set-operator",
          this.user.value,
          this.department,
          this.entity
        );
      }
    },
    acceptCloseChat() {
      if (!this.closingReason) {
        this.$vs.notify({
          time: 5000,
          title: this.$t("Error"),
          text: "Preencha todos os campos corretamente",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
          position: "top-right",
        });
        return;
      }

      let finalClosingReason = JSON.parse(JSON.stringify(this.closingReason));

      if (finalClosingReason.subReasons.length > 0) {
        finalClosingReason.subReasons = this.closingSubReason;

        if (this.closingReasonSaleValue) finalClosingReason.closingReasonSaleValue = this.closingReasonSaleValue;
      }
      if (this.saleCustomValue) finalClosingReason.saleCustomValue = this.saleCustomValue;

      this.$emit("close-conversation", finalClosingReason);
    },
  },
};
</script>
<style scoped lang="scss">
.btn-close {
  padding: 15px !important;
}
#icon-status > span > .con-vs-avatar {
  background-color: inherit !important;
}
#navbar-url {
  width: 200px;
  margin-top: -5px;
}
/*#navbar-operator {
  padding-right: 10px; width:150px;
}*/
.navbar-label {
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

input:focus {
  border: 1px solid rgba(var(--vs-primary), 1) !important;
}
</style>
